import tingle from "tingle.js/src/tingle"
import { qq, q } from "../helper/q"
import { Ias } from "./ias"
import axios from "axios"

export const dealerInit = () => {
  const buttons = qq(".dealersElement")

  if (q("body").classList.contains("dealers-page")) {
    const iasElements = qq(".dealersBlock")

    if (iasElements) {
      iasElements.map(iasElement => {
        if (
          iasElement.querySelector(".pageMoreNav") &&
          iasElement.querySelector(".pageMoreNav").dataset.countPage != 1
        ) {
          const ias = new Ias(iasElement)
          ias.init()
        }
      })
    }
    const modal = new tingle.modal({
      footer: false,
      stickyFooter: false,
      closeMethods: ["overlay", "escape"],
      closeLabel: "Close"
    })

    if (location.hash) {
      openHashModal(modal)
    }

    // window.addEventListener(
    //   "popstate",
    //   () => {
    //     if (location.hash) {
    //       openHashModal(modal)
    //     }
    //   },
    //   false
    // )
    window.onhashchange = () => {
      if (location.hash) {
        openHashModal(modal)
      }
    }
    if (buttons.length > 0) {
      buttons.map(button => {
        button.addEventListener("click", e => {
          e.stopPropagation()
        })
      })
    }
  }
}

const openHashModal = modal => {
  const url = location.href.split("#")

  axios({ method: "get", url: `${url[0] + url[1]}/?ajax=yes`, responseType: "text" })
    // .then(content => content.text())
    .then(data => {
      modal.setContent(data.data)
    })
    .then(() => {
      q(".dealersPopupClose", modal.getContent()).addEventListener("click", () => {
        modal.close()
        modal.setContent(`<div class="dealersPopup" style="height: 100vh"></div>`)
        window.history.pushState("", "", document.location.pathname)
      })
      modal.open()
    })
    .catch(err => {
      console.log("Error on Promise", err)
    })
}
