import { qq, q } from "../helper/q"

import pose from "popmotion-pose"
import { styler, tween } from "popmotion"

const countrysProps = {
  initialPose: "closed",
  open: {
    height: "auto",
    opacity: 1,
    delay: 0,
    beforeChildren: true,
    staggerChildren: 25
  },
  closed: {
    height: 0,
    opacity: 0,
    delay: 0
  }
}

const countryProps = {
  initialPose: "closed",
  open: {
    opacity: 1,
    y: 0
  },
  closed: {
    opacity: 0,
    y: 20
  }
}

const mapProps = {
  initialPose: "closed",
  open: {
    fill: "#76bf44"
  },
  closed: {
    fill: "#ccc"
  }
}

const state = { index: 0 }
export const initRegions = () => {
  const regionElement = q(".aboutMarket")
  const buttons = qq(".aboutRegionOption")
  const countrySection = qq(".aboutCountriesColumn")

  if (!regionElement) return null

  const progressValue = q(".aboutProgressValue span")
  const updateProgressValue = v => (progressValue.innerHTML = v + "%")
  const path = q(".aboutProgressCircleMain")
  const pathStyler = styler(path)

  const countryPosers = countrySection.map(section => {
    return pose(section, countrysProps)
  })

  countrySection.map((section, index) => {
    qq("span", section).map(span => {
      countryPosers[index].addChild(span, countryProps)
      q(`#${span.dataset.country}`) && countryPosers[index].addChild(q(`#${span.dataset.country}`), mapProps)
    })
  })

  countryPosers[0].set("open")

  buttons.map((button, index) => {
    button.addEventListener("click", () => {
      removeActives(buttons)

      countryPosers[state.index].set("closed").then(() => {
        button.classList.add("aboutRegionOptionActive")
        countryPosers[index].set("open")

        tween({
          from: parseFloat(progressValue.dataset.mass),
          to: parseFloat(button.dataset.mass),
          duration: 800
        })
          .pipe(Math.round)
          .start(updateProgressValue)

        progressValue.dataset.mass = button.dataset.mass

        const newPathLength = 232 - (232 / 100) * parseFloat(button.dataset.mass)
        tween({ to: -newPathLength }).start(v => pathStyler.set("strokeDashoffset", v))

        state.index = index
      })
    })
  })
}

const removeActives = buttons => {
  buttons.map(button => {
    button.classList.remove("aboutRegionOptionActive")
  })
}
