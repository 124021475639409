import { stickInit } from "./headroom"
import { menuDesktopInit } from "./menu"
import { mobileMenuInit } from "./mobile"
import { langInit } from "./lang";

export const headerInit = () => {
  stickInit()
  menuDesktopInit()
  mobileMenuInit()
  langInit()
}
