import { q } from "../helper/q"
import Headroom from "headroom.js"

export const stickInit = () => {
  const header = q(".header")
  if (header) {
    const headroom = new Headroom(header, {
      offset: 30,
      onPin: () => {
        document.body.classList.add("header-pin")
      },
      onUnpin: () => {
        document.body.classList.remove("header-pin")
      }
    })
    headroom.init()
  }
}
