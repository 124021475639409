import tingle from "tingle.js"
import { qq, q } from "../helper/q"

export const productPopupInit = () => {
  const buttons = qq("button[data-popup]")

  if (q("body").classList.contains("products-page")) {
    const modal = new tingle.modal({
      footer: false,
      stickyFooter: false,
      closeMethods: ["overlay", "escape"],
      closeLabel: "Close"
    })

    if (buttons.length > 0) {
      buttons.map(button => {
        button.addEventListener("click", e => {
          fetch(button.dataset.popup, {})
            .then(content => content.text())
            .then(data => {
              modal.setContent(data)
              q(".plywoodPopupClose", modal.getContent()).addEventListener("click", () => {
                modal.close()
                modal.setContent(`<div class="plywoodPopup" style="height: 100vh"></div>`)
              })
            })
            .then(() => {
              modal.open()
            })
        })
      })
    }
  }
}
