import { q } from "../helper/q"

export const searchHeaderInit = () => {
  const searchButton = q(".headerSearch")
  const searchPanel = q(".search")
  const clearButton = q(".searchClose")
  const overlay = q(".blackout")
  searchButton.addEventListener("click", () => {
    searchPanel.classList.toggle("searchVisible")
    overlay.classList.toggle("blackoutVisible")
    overlay.addEventListener("click", closeSearch)
  })
  const closeSearch = () => {
    searchPanel.classList.remove("searchVisible")
    overlay.classList.remove("blackoutVisible")
    overlay.removeEventListener("click", closeSearch())
  }

  clearButton.addEventListener("click", () => {
    q(".searchInput", searchPanel).value = ""
  })
}
