import "focus-visible"

import objectFitImages from "object-fit-images"
import { InitScroll } from "./global/scroll"
import { aboutInit } from "./about"
import { headerInit } from "./header"
import { newsInit } from "./news"
import { searchInit } from "./search"
import { dealerInit } from "./dealer"
import { dealerMapInit } from "./dealer/map"
import { productsInit } from "./product"
import { cookieInit } from "./cookie/cookie"

document.addEventListener("DOMContentLoaded", function() {
  objectFitImages()
  InitScroll()
  headerInit()
  aboutInit()
  newsInit()
  searchInit()
  dealerInit()
  productsInit()
  cookieInit()
})

export const dealerGoogleInit = () => {
  dealerMapInit()
}
