import { q, qq } from "../helper/q"

export const mobileMenuInit = () => {
  const menuElement = q(".menuMobile")
  const menuButtonElement = q(".menuForm")
  const menuItemElements = qq(".menuMobileElement")
  if (menuElement) {
    menuButtonElement.addEventListener("click", () => {
      menuButtonElement.classList.toggle("menuFormActive")
      menuElement.classList.toggle("menuMobileVisible")
    })

    menuItemElements.map(item => {
      q(".menuMobileName", item).addEventListener("click", () => {
        item.classList.toggle("menuMobileElementActive")
      })
    })
  }
}
