import InfiniteScroll from "infinite-scroll"
import { q } from "../helper/q"

// Init & reinit IAS

export class Ias {
  constructor(element) {
    this.infScroll = {}
    this.options = {
      path: function() {
        const parser = element.querySelector(".pageMoreNav")
        const count = parser.search.split("&")[0].split("=")
        if (this.loadCount + 1 < parser.dataset.countPage) {
          return (
            parser.pathname + count[0] + "=" + (this.loadCount + 2) + "&" + parser.search.split("&")[1] + "&ajax=yes"
          )
        }
      },
      append: ".articleCard",
      history: true,
      // status: element.querySelector(".page-load-status"),
      checkLastPage: true,
      loadOnScroll: false,
      // scrollThreshold: false,
      button: element.querySelector(".buttonGreen")
    }
    this.element = element
  }

  init() {
    const button = q(".pageMore .buttonGreen")
    const spinner = `<div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>`
    let buttonText
    this.infScroll = new InfiniteScroll(this.element.querySelector(".articlesCards"), this.options)

    if (button) {
      buttonText = button.innerHTML

      this.infScroll.on("request", () => {
        if (button) {
          button.style.width = button.offsetWidth + "px"
          button.style.height = button.offsetHeight + "px"
          button.innerHTML = spinner
        }
      })
      this.infScroll.on("append", () => {
        button.innerHTML = buttonText
        button.style.width = "auto"
        button.style.height = "auto"
      })
    }
  }
  reinit() {
    this.infScroll && this.infScroll.destroy()
    this.init()
  }
}
