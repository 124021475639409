import { Ias } from "./ias"

export const newsInit = () => {
  const iasElements = Array.from(document.querySelectorAll(".articlesCardsContent"))
  if (iasElements) {
    iasElements.map(iasElement => {
      if (iasElement.querySelector(".pageMoreNav") && iasElement.querySelector(".pageMoreNav").dataset.countPage != 1) {
        const ias = new Ias(iasElement)
        ias.init()
      }
    })
  }
}
