import { q } from "../helper/q"
import axios from "axios"

/*global google */

export const dealerMapInit = () => {
  const stylesMap = [
    {
      featureType: "administrative",
      elementType: "all",
      stylers: [
        {
          saturation: "-100"
        }
      ]
    },
    {
      featureType: "administrative.province",
      elementType: "all",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          saturation: -100
        },
        {
          lightness: 65
        },
        {
          visibility: "on"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          saturation: -100
        },
        {
          lightness: "50"
        },
        {
          visibility: "simplified"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: "-100"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified"
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "all",
      stylers: [
        {
          lightness: "30"
        }
      ]
    },
    {
      featureType: "road.local",
      elementType: "all",
      stylers: [
        {
          lightness: "40"
        }
      ]
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          saturation: -100
        },
        {
          visibility: "simplified"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          hue: "#ffff00"
        },
        {
          lightness: -25
        },
        {
          saturation: -97
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels",
      stylers: [
        {
          lightness: -25
        },
        {
          saturation: -100
        }
      ]
    }
  ]

  const mapElement = q("#map")
  if (mapElement) {
    const mapDealers = new google.maps.Map(mapElement, {
      scrollwheel: false,
      zoom: 17,
      minZoom: 2,
      center: new google.maps.LatLng(55.755825, 37.617298),
      streetViewControl: false,
      mapTypeControl: false,
      styles: stylesMap,
      scaleControl: false,
      zoomControl: false,
      fullscreenControl: false
    })

    const bounds = new google.maps.LatLngBounds()
    const markers = {}
    axios("/ajax/getDealers.php")
      .then(res => res.data)
      .then(address =>
        address.map(item => {
          markers[item.id] = new google.maps.Marker({
            icon: {
              url: "/local/templates/ilim/img/marker-ilimtimber-white.svg",
              size: new google.maps.Size(41, 41),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(20, 20),
              scaledSize: new google.maps.Size(41, 41),
              labelOrigin: new google.maps.Point(41, 41)
            },
            position: new google.maps.LatLng(item.latitude, item.longitude),
            map: mapDealers,
            optimized: false
          })

          bounds.extend(new google.maps.LatLng(item.latitude, item.longitude))
        })
      )
      .then(() => {
        mapDealers.setCenter(bounds.getCenter())
        mapDealers.fitBounds(bounds)
      })

    const listener = google.maps.event.addListener(mapDealers, "idle", function() {
      mapDealers.setZoom(mapDealers.getZoom() - 1)
      google.maps.event.removeListener(listener)
    })
  }
}
