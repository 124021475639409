import Cookies from 'js-cookie'
import { q } from '../helper/q'

export const cookieInit = () => {
	const currentCoookie = Cookies.get('cookies_accept')
	if (!currentCoookie) {
		q('.cookieAccept').classList.add('cookieAcceptVisible')
	}
	q('.cookieAcceptButton').addEventListener('click', () => {
		Cookies.set('cookies_accept', true)
		q('.cookieAccept').classList.remove('cookieAcceptVisible')
	})
}
