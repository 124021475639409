import { q, qq } from "../helper/q"

export const langInit = () => {
  const state = { open: false, click: false, touch: false }
  const button = q(".selectLanguage")
  const dropdown = q(".selectLanguageOptions")
  const element = q(".headerColumnLanguage")
  const isTouch = "ontouchstart" in window || (window.DocumentTouch && document instanceof DocumentTouch)

  testTouch()

  window.addEventListener("resize", testTouch)

  function testTouch() {
    if (isTouch) {
      state.touch = true
      initTouch()
    } else {
      state.touch = false
      initHover()
    }
  }

  function initTouch() {
    button.addEventListener("touchstart", () => {
      state.click = true
    })
    button.addEventListener("touchmove", () => {
      state.click = false
    })
    button.addEventListener("touchend", () => {
      if (state.click) {
        state.click = false
        button.classList.toggle("selectLanguageActive")
        dropdown.classList.toggle("selectLanguageOptionsActive")
      }
    })
  }
  function initHover() {
    element.addEventListener("mouseenter", () => {
      if (state.click === false) {
        state.open = true
        button.classList.add("selectLanguageActive")
        dropdown.classList.add("selectLanguageOptionsActive")
      }
    })

    element.addEventListener("mouseleave", () => {
      if (state.click === false) {
        state.open = false
        button.classList.remove("selectLanguageActive")
        dropdown.classList.remove("selectLanguageOptionsActive")
      }
    })
  }
}
