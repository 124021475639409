import { Ias } from "./ias"
import { searchHeaderInit } from "./search"

export const searchInit = () => {
  const iasElements = Array.from(document.querySelectorAll(".searchContent"))
  if (iasElements) {
    iasElements.map(iasElement => {
      if (iasElement.querySelector(".pageMoreNav") && iasElement.querySelector(".pageMoreNav").dataset.countPage != 1) {
        const ias = new Ias(iasElement)
        ias.init()
      }
    })
  }
  searchHeaderInit()
}
