import tingle from "tingle.js"
import { qq, q } from "../helper/q"

export const videoPopupInit = () => {
  const videomodal = new tingle.modal({
    footer: false,
    stickyFooter: false,
    closeMethods: ["overlay", "escape"],
    closeLabel: "Close",
    onClose: () => {
      close()
    }
  })

  const linksBlock = q(".sectionLinks")
  const links = qq("a", linksBlock)
  if (linksBlock) {
    links.map(link => {
      if (matchYoutubeUrl(link.href)) {
        link.addEventListener("click", e => {
          e.preventDefault()
          videomodal.setContent(`<div class="video"><iframe id="player" type="text/html"
        src="https://www.youtube.com/embed/${matchYoutubeUrl(link.href)}"
        frameborder="0"></iframe></div>`)
          videomodal.open()
        })
      }
    })
  }
  const close = () => {
    videomodal.setContent("")
  }
}

const matchYoutubeUrl = url => {
  const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
  return url.match(p) ? RegExp.$1 : false
}
