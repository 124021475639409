import { productPopupInit } from "./popup"
import { videoPopupInit } from "./video"
import { tableInit } from "./table"
import { zoomInit } from "./zoom"
export const productsInit = () => {
  productPopupInit()
  videoPopupInit()
  tableInit()
  zoomInit()
}
