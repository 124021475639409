import { easing, tween } from "popmotion"

import pose from "popmotion-pose"
import { q, qq } from "../helper/q"

export const menuDesktopInit = () => {
  const menuWrappers = qq(".headerElement")

  const menuProps = {
    initialPose: "closed",
    open: {
      opacity: 1,
      y: 0,
      zIndex: 20,
      delayChildren: 100,
      staggerChildren: 100,
      transition: props =>
        tween({
          ...props,
          duration: 600,
          ease: props.key === "opacity" ? easing.linear : easing.backOut
        })
    },
    closed: {
      delay: 0,
      zIndex: 10,
      staggerChildren: 10,
      opacity: 0,
      y: 25,
      transition: props =>
        tween({
          ...props,
          duration: props.key === "y" ? 1000 : 200,
          ease: props.key === "opacity" ? easing.linear : easing.linear
        })
    }
  }

  const menuTextProps = {
    initialPose: "closed",
    // hoverable: true,
    open: {
      opacity: 1,
      y: 0
    },
    closed: {
      opacity: 0,
      y: 20
    }
    // hover: {
    //   y: -3,
    //   duration: 400
    // }
  }

  const arrowProps = {
    initialPose: "closed",

    open: {
      opacity: 1,
      scale: 1,
      delay: 600
    },
    closed: {
      opacity: 0,
      scale: 0
    }
  }

  menuWrappers.map(menuWrapper => {
    if (q(".headerMenu", menuWrapper)) {
      const menu = q(".headerMenu", menuWrapper)
      const menuArrow = q(".headerMenuNameArrow", menuWrapper)
      const menuText = qq(".headerMenu a", menuWrapper)
      const menuPoser = pose(menu, menuProps)
      const arrowPoser = pose(menuArrow, arrowProps)

      menuText.forEach((text, i) => {
        menuPoser.addChild(text, {
          ...menuTextProps,
          transitionProps: { i }
        })
      })

      menuWrapper.addEventListener("mouseenter", () => {
        menuPoser.set("open")
        arrowPoser.set("open")
      })
      menuWrapper.addEventListener("mouseleave", () => {
        arrowPoser.set("closed")
        menuPoser.set("closed")
      })
    }
  })
}
