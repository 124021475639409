import pose from "popmotion-pose"
import { q, qq } from "../helper/q"
import { scroll } from "../global/scroll"

const mq = window.matchMedia("(min-width: 992px)")

const parent = {
  initialPose: "closed",
  // hoverable: true,
  open: {
    opacity: 1,
    y: 0
  },
  closed: {
    opacity: 1,
    y: 0
  }
}

const children = {
  initialPose: "closed",
  // hoverable: true,
  open: {
    height: "auto",
    marginBottom: 29
  },
  closed: {
    height: 0,
    marginBottom: 0
  }
}

const childrenRow = {
  initialPose: "closed",
  // hoverable: true,
  open: {
    height: "auto",
    applyAtStart: { display: "flex" },
    applyAtEnd: { borderBottomWidth: !mq.matches ? "2px" : "1px" }
  },
  closed: {
    applyAtEnd: { display: "none" },
    applyAtStart: { borderBottomWidth: "0px" },
    height: 0
  }
}

const state = { open: false }

export const tableInit = () => {
  const element = q(".plywoodPerformanceTableHidden")
  const tables = qq(".plywoodPerformanceTable", element)
  const buttonElement = q(".plywoodBlockPerformance button")
  if (element) {
    const poser = pose(element, parent)
    tables.map((table, index) => {
      if (index === 0) {
        qq(".plywoodPerformanceTableRow", table).map((row, index) => {
          if (index > 4) {
            poser.addChild(row, childrenRow)
          }
        })
      }
      if (index !== 0) {
        poser.addChild(table, children)
      }
    })
    buttonElement.addEventListener("click", () => {
      if (!state.open) {
        poser.set("open")
        state.open = true
        buttonElement.style.width = buttonElement.offsetWidth + "px"
        buttonElement.innerHTML = buttonElement.dataset.closed
      } else {
        scroll.animateScroll(element.parentNode, { offset: 200 })
        poser.set("closed")
        state.open = false
        buttonElement.innerHTML = buttonElement.dataset.open
      }
    })
  }
}
