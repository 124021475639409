export const zoomInit = () => {
    const image = document.querySelector('.plywoodBlockMarkImage')
    const zoomContainer = document.querySelector('.plywoodBlockMarkImageZoom')
    const zoomImage = document.querySelector('.plywoodBlockMarkImageZoom img')
    const zoomMark = document.querySelector('.plywoodBlockMarkImageMark')
    if (!image) return null

    function touchHandler(event) {
        let touch = event.changedTouches[0]

        let simulatedEvent = document.createEvent("MouseEvent")
        simulatedEvent.initMouseEvent({
            touchstart: "mousedown",
            touchmove: "mousemove",
            touchend: "mouseup"
        }[event.type], true, true, window, 1,
            touch.screenX, touch.screenY,
            touch.clientX, touch.clientY, false,
            false, false, false, 0, null)

        touch.target.dispatchEvent(simulatedEvent)
    }


    document.addEventListener("touchstart", touchHandler, true)
    document.addEventListener("touchmove", touchHandler, true)
    document.addEventListener("touchend", touchHandler, true)
    document.addEventListener("touchcancel", touchHandler, true)


    window.addEventListener('mousemove', (e) => {
        moveImage({x: e.clientX, y: e.clientY})
    })


    function moveImage({x, y}) {
        let imageRect = image.getBoundingClientRect()

        if (imageRect.top > y) return null
        if (imageRect.bottom < y) return null

        let zoomContainerRect = zoomContainer.getBoundingClientRect()
        let zoomImageRect = zoomImage.getBoundingClientRect()

        let zoomMarkRect = zoomMark.getBoundingClientRect()
        let zoomMarkOffset = zoomMarkRect.width / 2
        let zoomMarkImagePadding = imageRect.width - zoomMarkOffset

        if (x < imageRect.x + zoomMarkRect.width / 2) {
            zoomImage.style.transition = 'all .3s ease-out'
            zoomImage.style.transform = 'translateX(' + - 0 + 'px)'
            zoomMark.style.transform = 'translateX(' + 0 + 'px)'
            return null
        }

        let rightMarkPosition = imageRect.width - zoomMarkRect.width

        if (x > (imageRect.x + (imageRect.width - + zoomMarkRect.width / 2))) {
            let rightZoomImagePosition = -(zoomContainerRect.width * 2)
            zoomImage.style.transition = 'all .3s ease-out'
            zoomImage.style.transform = 'translateX(' + rightZoomImagePosition + 'px)'
            zoomMark.style.transform = 'translateX(' + rightMarkPosition + 'px)'
            return null
        }

        let xPositionZoomImage = (x - zoomContainerRect.x) * 2
        let xPositionMark = (x - zoomContainerRect.x) - (zoomMarkRect.width / 2)
        let xPositionMarkOffset = imageRect.width - zoomMarkRect.width / 2
        zoomImage.style.transition = 'all .05s ease-out'
        zoomImage.style.transform = 'translateX(' + - xPositionZoomImage + 'px)'
        if ((zoomMarkRect.width / 2) < (x - zoomContainerRect.x) && (x - zoomContainerRect.x) < xPositionMarkOffset) {
            zoomMark.style.transform = 'translateX(' + xPositionMark + 'px)'
        }
    }
}
