import { q, qq } from "../helper/q"
import pose from "popmotion-pose"

const textProps = {
  initialPose: "closed",
  open: {
    delay: 0,
    delayChildren: 300,
    staggerChildren: 150
  },
  closed: {
    delay: 100,
    staggerChildren: 120
  }
}

const historyProps = {
  initialPose: "closed",
  open: {
    height: "100%",
    applyAtStart: {
      overflow: "visable"
    }
  },
  closed: {
    height: 0,
    applyAtStart: {
      overflow: "hidden"
    }
  }
}

export const historyInit = () => {
  const historyContainer = q(".aboutHistoryItems")
  const historyItems = qq(".aboutHistoryItem")
  if (historyContainer) {
    const historyPoser = pose(historyContainer, textProps)
    historyItems.slice(3, historyItems.length).forEach(item => historyPoser.addChild(item, historyProps))

    const historyButton = q(".pageLoadmore button")
    const state = {
      history: "close"
    }

    historyButton.addEventListener("click", () => {
      if (state.history === "close") {
        historyPoser.set("open")
        state.history = "open"
        historyButton.innerHTML = historyButton.dataset.messageClose
      } else {
        historyPoser.set("closed")
        state.history = "close"
        historyButton.innerHTML = historyButton.dataset.messageOpen
      }
    })
  }
}
