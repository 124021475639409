import SmoothScroll from "smooth-scroll"
import gumshoe from "gumshoejs"
import { q } from "../helper/q"

export let scroll

export const InitScroll = () => {
  scroll = new SmoothScroll('a[href*="#"]', {
    speed: 300,
    header: ".header",
    offset: 0
  })

  const buttonTop = q(".pageTop")
  if (buttonTop) {
    buttonTop.addEventListener("click", () => {
      scroll.animateScroll(0, { speed: 300, offset: 100 })
    })
  }

  gumshoe.init({
    activeClass: "pageMenuLinkActive",
    offset: 120
  })
}
